import * as React from "react"

import Header from "../header"
import Footer from "../footer"

const Contact = () => (

    <div className={`pageContainer`}>
        <Header hidePlaceholder={true} />

        <Footer variant={3} />
    </div>
)

export default Contact
