import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Contact from "../components/pages/Contact"

import { useIntl } from "gatsby-plugin-intl-v4"

const ContactPage = () => {

  const intl = useIntl()

  return (
    <Layout>
      <Seo
        title={intl.formatMessage({ id: 'h1.contact' })}
        description={intl.formatMessage({ id: 'seo.descriptionContact' })}
        pageName={'contact'}
      />
      <Contact />
    </Layout>
  )
}

export default ContactPage
